
import '../index.css';
import React from "react";
import Selfie from '../images/selfie.jpg';
import Project1 from '../images/tictactoe.jpg'
import Clock from 'react-live-clock';
import SocialFollow from "./SocialFollow";
import Map from './Map';
import Phone from './Phone';
import Footer from './Footer';

function Hero() {

    return (
      <><div className="hero">
        <div className='heroItem heroItem1' id='item1'>
          <h2>a bit about me.</h2>
          <p>I'm Joe, an independent, Full-Stack developer that can take your project from
            idea to completion.
          </p>
          <p>With a background in engineering, I have naturally developed an analytical
            approach to problem solving and materialisation of ideas.
          </p>
        </div>
        <div className='heroItem2'><Phone /></div>
        <div className='heroItem heroItem3'><img src={Selfie} /></div>
        <div className='heroItem heroItem4'>

          <div className="clock">
            <Clock format={'HH:MM A'} ticking={true} timezone={'Australia/Brisbane'} />
          </div>
          <h2>local time.</h2>
        </div>
        <div className='heroItem heroItem5'><h1>jm.</h1></div>
        <div className='heroItem heroItem6'><Map /></div>
        <div className='heroItem heroItem7'>
          <h2>projects.</h2>
          <div className='projects'>
            <a href="https://jrmilburn.github.io/tic-tac-toe/" className='project project1'>
              <img src={Project1}/>
            </a>
            <div className='project project2'></div>
            <div className='project project3'></div>
          </div>
        </div>
        <div className='contact'>
          <h2>work with me.</h2>
          <p>Get in touch to see how I can help bring your project to life.</p>
          <button>Contact</button>

        </div>
        <div className='socials'><SocialFollow /></div>
      </div>
      <Footer/></>
      
    );

  }

export default Hero;