import '../index.css';
import React from "react";


export default function Map() {
    return (
      <>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28321.045871082257!2d152.98966450399107!3d-27.465189033960062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b915a045cf620bb%3A0x502a35af3de84c0!2sBrisbane%20City%20QLD%204000!5e0!3m2!1sen!2sau!4v1714006277966!5m2!1sen!2sau" 
        width="100%" 
        height="100%" 
        style={{border: "0"}} 
        allowfullscreen="" 
        loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade"></iframe>
      </>
    );
  }