import '../index.css';
import React from "react";


export default function Footer() {
    return (
      <>
        <div class="footer">
            Copyright © Joe Milburn 2024
        </div>
      </>
    );
  }