
import '../index.css';
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faInstagram,
    faDiscord,
    faLinkedin
  } from "@fortawesome/free-brands-svg-icons";

export default function SocialFollow() {
    return (
      <><h2>socials.</h2><div className="social-container">

        <a href='#'><FontAwesomeIcon icon={faInstagram} size="2x" id='icon'/> @joe.milburn</a>
        <a href='#'><FontAwesomeIcon icon={faLinkedin} size="2x" id='icon'/> @Joseph Milburn</a>
        <a href='#'><FontAwesomeIcon icon={faDiscord} size="2x" id='icon'/> @jrmilburn</a>
        <a href='#'><FontAwesomeIcon icon={faGithub} size="2x" id='icon'/> @jrmilburn</a>
      </div></>
    );
  }